body {
  margin: 0;
  height: 100vh;
  color: #666;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  overflow-y: hidden;
}

#root {
  height: 100%;
}