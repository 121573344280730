
form {
    padding: 5%;
    width: 50%;

    input,
    button {
        padding: 1em;
        margin: 3%;
        width: 100%;
        border-radius: 5px;
        border: none;
        box-shadow: 3px 3px 10px #eee;
        font-size: 1.2em;
    }

    input:focus {
        outline-color: #29d4b9;
    }

    button[type=submit] {
        padding: 1em 2em;
        color: #fff;
        text-shadow: 1px 1px#06a186;
        background: #29d4b9;
        font-weight: bold;
        cursor: pointer;
        transition: ease 0.3s;

        &:hover {
            background: #18c3a8;
        }
        &:disabled {
            background: #128e7c;
            color: #033029;
        }
    }

    .forgot-password {
        width: 100%;
        text-align: right;
        font-size: 0.9em;
    }

    .styled-link {
        font-weight: bold;
        padding: 0 0.5em;
        color: #07b297;

        &:hover { 
            text-decoration: underline;
            cursor: pointer;
        }
    }

    @media (max-width: 768px) {
        width: 65%;
    }
}
